<mat-card *ngIf="!ready" class="flex-default">
  <p>Loading...</p>
</mat-card>
<mat-card *ngIf="ready" class="flex-col flex-default gap-10" [style.background-color]="backgroundColor()">
  <div class="flex-row justify-space-between align-start">
    <div *ngIf="room || (nomination && !editable)">
      <div>
        <span *ngIf="isOnlineRoom()" matTooltip="Online room">💻</span>
        <span *ngIf="isInPersonRoom()" matTooltip="In-person room">🔐</span>
        <span *ngIf="isCompany()" matTooltip="Company">🏢</span>
        <span *ngIf="(settings.isVotingOpen() || !settings.isCurrentYear(nomination?.year)) && isNominee()" matTooltip="Nominee">🎗️</span>
        <span *ngIf="(settings.isVotingOpen() || !settings.isCurrentYear(nomination?.year)) && isFinalist()" matTooltip="Finalist">🏅</span>
        <span *ngIf="!settings.isCurrentYear(nomination?.year) && isWinner()" matTooltip="Winner">🏆</span>
        <span *ngIf="isIneligible()" matTooltip="Ineligible">❌</span>
        <span *ngIf="isPermanentlyIneligible()" matTooltip="Permanently ineligible">⛔</span>
        <strong> {{ primaryName() }}</strong>
        <span *ngIf="horrorLevel()">
          {{' '}}<app-horrorlevel [level]="horrorLevel()" [showText]="false"></app-horrorlevel>
        </span>
        <span *ngFor="let l of languages()">
          {{' '}}<app-language [alpha2]="l"></app-language>
        </span>
      </div>
      <div *ngIf="secondaryName()">
        <i><strong> {{ secondaryName() }}</strong></i>
      </div>
      <div *ngIf="!isCompany()">
        {{ company() }}
      </div>
      <div>
        <i>{{ location() }}</i>
      </div>
      <div>
        <a href="{{ link() }}" target="_blank">{{ link() }}</a>
      </div>
    </div>
    <div *ngIf="allowEdit && !editable" class="flex-col justify-start align-end gap-5">
      <button mat-raised-button color="primary" (click)="toggleEditable()">
        Edit
      </button>
      <button mat-raised-button color="warn" (click)="delete()">
        Remove
      </button>
    </div>
    <div *ngIf="!room && editable"></div>
    <div *ngIf="nomination && editable" class="flex-row justify-flex-end align-start">
      <button *ngIf="formGroup.dirty" mat-raised-button color="primary" [disabled]="!isValidNomination()" (click)="save()">
        Save
      </button>
      <button *ngIf="!formGroup.dirty" mat-raised-button color="primary" (click)="toggleEditable()">
        Done Editing
      </button>
    </div>
    <div *ngIf="matcher && matcher.canProcessRawNominations()" class="flex-row justify-flex-end align-start gap-5">
      <button *ngIf="allowSelect && !selected" mat-raised-button color="primary" (click)="toggleSelected()">
        Select
      </button>
      <button *ngIf="allowSelect && selected" mat-raised-button color="primary" (click)="toggleSelected()">
        Unselect
      </button>
      <button *ngIf="nomination?.roomId" mat-raised-button color="primary" (click)="unmatch()">
        Unmatch
      </button>
    </div>
  </div>
  <form *ngIf="editable" [formGroup]="formGroup">
    <mat-form-field *ngIf="!room" appearance="fill" class="f">
      <mat-label>Room name</mat-label>
      <input #firstInput type="text" matInput formControlName="room" required>
    </mat-form-field>
    <mat-form-field *ngIf="!room" appearance="fill">
      <mat-label>Company</mat-label>
      <input type="text" matInput formControlName="company" required>
    </mat-form-field>
    <app-locationpicker *ngIf="!room" [formGroup]="formGroup" [requireCity]="!isOnlineRoom()"></app-locationpicker>
    <app-languagepicker
        *ngIf="!room && requiresEnglishCheck()"
        [formGroup]="formGroup" [country]="formGroup.value.country">
    </app-languagepicker>
    <div *ngIf="!room">
      <span class="instructions">
        What is this room's <a href="/faq#horror" target="_blank">horror level</a>?
      </span>
      <app-horrorlevelpicker [formGroup]="formGroup"></app-horrorlevelpicker>
    </div>
    <mat-form-field *ngIf="!room" appearance="fill">
      <mat-label>Website link</mat-label>
      <input type="text" matInput formControlName="link" required>
    </mat-form-field>
    <mat-form-field *ngIf="!room" appearance="fill">
      <mat-label>Email contact (optional)</mat-label>
      <input type="email" matInput formControlName="email">
    </mat-form-field>
    <span *ngIf="!room" class="instructions">
      Was this room open for at least 30 days between 1 Nov {{ year - 1 }} and 31 Oct {{ year }}?
    </span>
    <mat-form-field *ngIf="!room" appearance="fill">
      <mat-label>Confirm availability</mat-label>
      <select matNativeControl formControlName="confirmedDates" required>
        <option></option>
        <option [ngValue]="true">Yes, I'm certain it was open.</option>
        <option [ngValue]="false">I'm not sure, please investigate.</option>
      </select>
    </mat-form-field>
    <span *ngIf="!room && requiresEnglishCheck()" class="instructions">
      Is this room playable by an entirely English-speaking team?
    </span>
    <mat-form-field *ngIf="!room && requiresEnglishCheck()" appearance="fill">
      <mat-label>Confirm English playability</mat-label>
      <select matNativeControl formControlName="confirmedEnglish" required>
        <option></option>
        <option [ngValue]="true">Yes, I'm certain it is playable in English.</option>
        <option [ngValue]="false">I'm not sure, please investigate.</option>
      </select>
    </mat-form-field>
    <span class="instructions">
      Do you have a potential <a href="/faq#conflicts" target="_blank">conflict of interest</a> in nominating this room?
    </span>
    <mat-form-field appearance="fill">
      <mat-label>Confirm impartiality</mat-label>
      <select matNativeControl formControlName="confirmedNoConflicts" required>
        <option></option>
        <option [ngValue]="true">No, I have no conflict of interest.</option>
        <option [ngValue]="false">Yes, I am affiliated with this room.</option>
      </select>
    </mat-form-field>
    <span *ngIf="!room && requiresEnglishCheck() && formGroup.value.confirmedEnglish" class="instructions">
      Please help our team by providing a link to a specific webpage that confirms English availability. (optional)
    </span>
    <mat-form-field *ngIf="!room && requiresEnglishCheck() && formGroup.value.confirmedEnglish" appearance="fill">
      <mat-label>English availability link</mat-label>
      <input type="text" matInput formControlName="englishLink">
    </mat-form-field>
    <span class="instructions">
      If this room is available in multiple versions, languages, or locations, let us know which you played. (optional)
    </span>
    <mat-form-field appearance="fill">
      <mat-label>Which version(s) did you play?</mat-label>
      <textarea matInput formControlName="versionPlayed"
                cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMaxRows="3"></textarea>
    </mat-form-field>
    <div *ngIf="room">
      <span class="instructions">
        Is anything wrong with the listing name, languages, horror level, etc? (optional)
      </span>
      <mat-form-field appearance="fill">
        <mat-label>Describe any issues with listing data</mat-label>
        <textarea matInput formControlName="dataProblem"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMaxRows="3"></textarea>
      </mat-form-field>
    </div>
  </form>
  <p *ngIf="!nomination" class="flex-row justify-flex-start align-center">
    <button mat-raised-button color="primary" [disabled]="!isValidNomination()" (click)="save()">
      Nominate
    </button>
  </p>
  <div *ngIf="nomination && editable" class="flex-row justify-flex-end align-center">
    <button *ngIf="formGroup.dirty" mat-raised-button color="primary" [disabled]="!isValidNomination()" (click)="save()">
      Save
    </button>
    <button *ngIf="!formGroup.dirty" mat-raised-button color="primary" (click)="toggleEditable()">
      Done Editing
    </button>
  </div>
  <ng-container *ngIf="isValidNomination() && !editable && (showDetails || showUserData)">
    <div *ngIf="matcher && showDetails && !nomination.userMatched && ([true,false].includes(nomination.confirmedDates) || [true,false].includes(nomination.confirmedEnglish))"
         class="flex-default flex-row flex-wrap justify-space-between align-start gap-5">
      <span *ngIf="[true,false].includes(nomination.confirmedDates)">
        Dates:&nbsp;<span *ngIf="nomination.confirmedDates">✅</span><span *ngIf="!nomination.confirmedDates">❓</span>
      </span>
      <span *ngIf="[true,false].includes(nomination.confirmedEnglish)">
        English:&nbsp;<span *ngIf="nomination.confirmedEnglish">✅</span><span *ngIf="!nomination.confirmedEnglish">❓</span>
        <span *ngIf="englishLink()">&nbsp;<a href="{{ englishLink() }}" target="_blank">{{ englishLink() }}</a></span>
        <span *ngIf="nomination.confirmedEnglish && !englishLink()">&nbsp;(no link)</span>
      </span>
    </div>
    <div *ngIf="showDetails && nomination.versionPlayed" class="terpeca-multiline">
      Version: {{ nomination.versionPlayed }}
    </div>
    <div *ngIf="showDetails && nomination.dataProblem" class="warning-box">
      <div class="terpeca-multiline"> <!-- must nest inside warning-box -->
        Problem report: {{ nomination.dataProblem }}
      </div>
    </div>
    <div *ngIf="showDetails && nomination.quote" class="terpeca-multiline">
      Quote: {{ nomination.quote }}
    </div>
    <div *ngIf="showUserData && userData">
      {{ userData.realName || userData.displayName }} ({{ userData.roomCount }}) - {{ getLocationString(userData) }} {{ userData.videoContributionInterest ? '🎥' : '' }}
    </div>
  </ng-container>
  <button mat-raised-button *ngIf="!isValidNomination() && !editable" (click)="toggleEditable()" color="primary">
    🗒️ This nomination requires additional data.
  </button>
  <div *ngIf="isValidNomination() && isIneligible() && !editable" class="warning-box flex-col flex-default gap-5">
    <div>
      ❌ This room was marked ineligible.
    </div>
    <div *ngIf="room?.ineligibilityReason">
      Reason: <i>{{ room.ineligibilityReason }}</i>
    </div>
    <div *ngIf="!matcher && nomination?.year === year && !settings.isPastNominationDeadline()">
      To appeal this decision, submit this as is and email admin&#64;terpeca.com with your reasoning.
      Otherwise, replace it with another nomination.
    </div>
  </div>
  <div *ngIf="isValidNomination() && isApproved() && !editable && showEligibility" class="yellow-box">
    ✅ This room was approved as eligible.
  </div>
  <div *ngIf="isValidNomination() && !isApproved() && !isIneligible() && !editable && showEligibility" class="info-box">
    ❓ This room has not yet been processed.
  </div>
</mat-card>
