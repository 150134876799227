<section class="flex-col justify-center align-center">
  <h3>Your Profile</h3>
  <form [formGroup]="formGroup" class="flex-row flex-wrap gap-5 justify-center">
    <mat-card>
      <h4 class="flex-row justify-center">
        Public Data
      </h4>
      <p>
        <mat-hint>
          The data in this section will be published at the terpeca.com website. Full and transparent disclosure of your identity and your relevant
          affiliations is required to participate in the Top Escape Rooms Project.
        </mat-hint>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Real name</mat-label>
          <input type="text" matInput formControlName="realName" required>
          <mat-hint>Your real name (first and last)</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <app-locationpicker [formGroup]="formGroup" [forUser]=true></app-locationpicker>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Total escape rooms played</mat-label>
          <input type="number" matInput formControlName="roomCount" required>
          <mat-hint>Estimate to the nearest round number <i>that you have definitely reached</i> if you don't have an exact count.
            DO include online escape rooms as defined below in this count.
            For clarification, see the <a href="/faq#what-counts" target="_blank">FAQ</a>.</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Total online escape rooms played</mat-label>
          <input type="number" matInput formControlName="virtualRoomCount" required [errorStateMatcher]="roomCountErrorMatcher">
          <mat-hint>This should include <b>only</b> hosted escape rooms played online, and these games should also
            be included in your total count above. For clarification, see the
            <a href="/faq#what-counts-online" target="_blank">FAQ</a>.</mat-hint>
          <mat-error *ngIf="formGroup.errors?.invalidRoomCounts">
            Online room count cannot exceed total room count.
          </mat-error>
        </mat-form-field>
      </p>
      <p style="margin-bottom: 100px">
        <mat-form-field appearance="fill">
          <mat-label>Bio</mat-label>
          <textarea matInput formControlName="bio" required
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
          <mat-hint>
            A short, well-written bio to publicly represent you on the TERPECA website. For full instructions, see the
            <a href="/faq#bio" target="_blank">FAQ</a>.
          </mat-hint>
        </mat-form-field>
      </p>
    </mat-card>
    <mat-card>
      <h4 class="flex-row justify-center">
        Private Data
      </h4>
      <p>
        <mat-hint>
          The data in this section can be seen only by TERPECA ambassadors and board members, and will not be shared externally.
        </mat-hint>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Email address</mat-label>
          <input type="email" matInput formControlName="appEmail" required>
          <mat-hint>We will use this if we need to contact you, but we won't share it with anyone.</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Facebook userid</mat-label>
          <input type="text" matInput formControlName="facebookUsername">
          <mat-hint>
            To determine your userid, visit your Facebook profile page.
            If the URL is <i>facebook.com/john.doe7</i>, enter <i>john.doe7</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.facebookUsername.hasError('pattern')">
            Your Facebook userid is invalid. To determine your userid, visit your Facebook profile page.
            If the URL is <i>facebook.com/john.doe7</i>, enter <i>john.doe7</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Instagram userid</mat-label>
          <input type="text" matInput formControlName="instagramUsername">
          <mat-hint>
            To determine your userid, visit your Instagram profile page.
            If the URL is <i>instagram.com/john.doe7</i>, enter <i>john.doe7</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.instagramUsername.hasError('pattern')">
            Your Instagram userid is invalid. To determine your userid, visit your Instagram profile page.
            If the URL is <i>instagram.com/john.doe7</i>, enter <i>john.doe7</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Discord username</mat-label>
          <input type="text" matInput formControlName="discordUsername">
          <mat-hint>To determine your username, visit your Discord profile.</mat-hint>
          <mat-error *ngIf="formGroup.controls.discordUsername.hasError('pattern')">
            Your Discord username is invalid. To determine your username, visit your Discord profile.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Best contact method</mat-label>
          <select matNativeControl formControlName="bestContactMethod" required>
            <option value=""></option>
            <option *ngIf="formGroup.value.appEmail || formGroup.value.bestContactMethod === 'email'"
                    value="email">email to {{ formGroup.value.appEmail || '(specify above)' }}</option>
            <option *ngIf="(user.email !== formGroup.value.appEmail) || formGroup.value.bestContactMethod === 'verifiedemail'"
                    value="verifiedemail">email to {{ user.email || '(n/a)' }}</option>
            <option *ngIf="formGroup.value.facebookUsername || formGroup.value.bestContactMethod === 'facebook'"
                    value="facebook">Facebook message to {{ formGroup.value.facebookUsername || '(specify above)' }}</option>
            <option *ngIf="formGroup.value.instagramUsername || formGroup.value.bestContactMethod === 'instagram'"
                    value="facebook">Instagram message to {{ formGroup.value.instagramUsername || '(specify above)' }}</option>
            <option *ngIf="formGroup.value.discordUsername || formGroup.value.bestContactMethod === 'discord'"
                    value="facebook">Discord message to {{ formGroup.value.discordUsername || '(specify above)' }}</option>
          </select>
          <mat-hint>Choose the most reliable way to get in touch with you if we have questions for you.</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <app-horrorpreferencepicker [formGroup]="formGroup"></app-horrorpreferencepicker>
      </p>
      <p class="flex-col">
        <mat-label class="flex-row justify-center"><b>Room-Tracking Accounts</b></mat-label>
      </p>
      <p>
        <mat-hint>
          The data in this section will not be shared publicly, but we do reserve the right to let any of the websites listed below
          know that you are a TERPECA contributor. If we do so, we will only share your userid on their site with them, and we will
          not include any of your other private data.
        </mat-hint>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>EscapeTheReview.co.uk userid</mat-label>
          <input type="text" matInput formControlName="escapeTheReviewUserId">
          <mat-hint>
            To determine your userid, visit your EscapeTheReview.co.uk profile page.
            If the URL is <i>escapethereview.co.uk/profile/?user_id=1234</i>, enter <i>1234</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.escapeTheReviewUserId.hasError('pattern')">
            Your EscapeTheReview.co.uk userid is invalid. To determine your userid, visit your EscapeTheReview.co.uk profile page.
            If the URL is <i>escapethereview.co.uk/profile/?user_id=1234</i>, enter <i>1234</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>EscapeAll.gr userid</mat-label>
          <input type="text" matInput formControlName="escapeAllGrUserId">
          <mat-hint>
            To determine your userid, visit your EscapeAll.gr profile page.
            If the URL is <i>escapeall.gr/el/User/Profile/1234abc-567</i>, enter <i>1234abc-567</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.escapeAllGrUserId.hasError('pattern')">
            Your EscapeAll.gr userid is invalid. To determine your userid, visit your EscapeAll.gr profile page.
            If the URL is <i>escapeall.gr/el/User/Profile/1234abc-567</i>, enter <i>1234abc-567</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>EscapeGame.fr userid</mat-label>
          <input type="text" matInput formControlName="escapeGameFrUserId">
          <mat-hint>
            To determine your userid, visit your EscapeGame.fr profile page.
            If the URL is <i>escapegame.fr/profil/abcdefg</i>, enter <i>abcdefg</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.escapeGameFrUserId.hasError('pattern')">
            Your EscapeGame.fr userid is invalid. To determine your userid, visit your EscapeGame.fr profile page.
            If the URL is <i>escapegame.fr/profil/abcdefg</i>, enter <i>abcdefg</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Escape Roomers (Italy) userid</mat-label>
          <input type="text" matInput formControlName="escapeRoomersUserId">
          <mat-hint>
            To determine your userid, visit your Escape-Roomers.com profile page.
            If the URL is <i>escape-roomers.com/utente_home/123x456</i>, enter <i>123x456</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.escapeRoomersUserId.hasError('pattern')">
            Your Escape-Roomers.com userid is invalid. To determine your userid, visit your Escape-Roomers.com profile page.
            If the URL is <i>escape-roomers.com/utente_home/123x456</i>, enter <i>123x456</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>EscapeTalk.nl userid</mat-label>
          <input type="text" matInput formControlName="escapeTalkNlUserId">
          <mat-hint>
            To determine your userid, visit your EscapeTalk.nl profile page.
            If the URL is <i>escapetalk.nl/profiel/johndoe7</i>, enter <i>johndoe7</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.escapeTalkNlUserId.hasError('pattern')">
            Your EscapeTalk.nl userid is invalid. To determine your userid, visit your EscapeTalk.nl profile page.
            If the URL is <i>escapetalk.nl/profiel/johndoe7</i>, enter <i>johndoe7</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>LockMe.pl userid</mat-label>
          <input type="text" matInput formControlName="lockMeUserId">
          <mat-hint>
            To determine your userid, visit your LockMe profile page.
            If the URL is <i>lock.me/pl/user/123456</i>, enter <i>123456</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.lockMeUserId.hasError('pattern')">
            Your LockMe userid is invalid. To determine your userid, visit your LockMe profile page.
            If the URL is <i>lock.me/pl/user/123456</i>, enter <i>123456</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Morty username</mat-label>
          <input type="text" matInput formControlName="mortyAppUsername">
          <mat-hint>
            To determine your username, visit your Morty profile page.
            If the URL is <i>morty.app/&#64;johndoe7</i>, enter <i>johndoe7</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.mortyAppUsername.hasError('pattern')">
            Your Morty username is invalid. To determine your username, visit your Morty profile page.
            If the URL is <i>morty.app/&#64;johndoe7</i>, enter <i>johndoe7</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>The Escapers (France) userid</mat-label>
          <input type="text" matInput formControlName="theEscapersUserId">
          <mat-hint>
            To determine your userid, visit your The Escapers profile page.
            If the URL is <i>the-escapers.com/profil/john-doe-7</i>, enter <i>john-doe-7</i> here.
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.theEscapersUserId.hasError('pattern')">
            Your The Escapers userid is invalid. To determine your userid, visit your The Escapers profile page.
            If the URL is <i>the-escapers.com/profil/john-doe-7</i>, enter <i>john-doe-7</i> here.
          </mat-error>
        </mat-form-field>
      </p>
      <p *ngIf="user.status >= Status.REVIEWER && user.ambassadorCountries?.length" class="flex-col gap-5">
        <mat-label class="flex-row justify-center"><b>Ambassador Options</b></mat-label>
        <mat-hint class="flex-row justify-center">Countries: {{ user.ambassadorCountries.join(', ') }}</mat-hint>
        <mat-checkbox formControlName="notifyOnNewApplicant">Notify me by email when there is a new applicant to review.</mat-checkbox>
        <mat-checkbox formControlName="notifyOnNewRoom">Notify me by email when there is a new room to process.</mat-checkbox>
      </p>
    </mat-card>
  </form>
  <p>
    <button mat-raised-button color="primary" [disabled]="!formGroup.dirty" (click)="save(formGroup)">Save</button>
    <button mat-raised-button color="primary" [disabled]="formGroup.dirty" routerLink="/">Back to Home</button>
  </p>
</section>
