import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import app from 'firebase/compat/app';
import { Subscription } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { TerpecaRanking } from 'src/app/models/ranking.model';
import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { ApplicationStatus, TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { RoomCountErrorStateMatcher, roomCountValidator } from 'src/app/utils/form.utils';
import * as utils from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

import { SimpleDialogComponent } from '../simpledialog/simpledialog.component';

@Component({
  selector: 'app-nominate',
  templateUrl: './nominate.component.html',
  styleUrl: './nominate.component.css'
})
export class NominateComponent implements OnInit, OnDestroy {
  user: TerpecaUser;
  regularNominationIds: string[];
  regularNominatedRoomIds: string[];
  onlineNominationIds: string[];
  onlineNominatedRoomIds: string[];
  companyNominationIds: string[];
  regularRooms: TerpecaRoom[] = [];
  regularRoomMap: Map<string, TerpecaRoom>;
  regularAutogenRoomIds: Set<string>;
  onlineRooms: TerpecaRoom[] = [];
  onlineRoomMap: Map<string, TerpecaRoom>;
  onlineAutogenRoomIds: Set<string>;
  MAX_SEARCH_RESULTS = 50;
  NOMINATE_REGULAR_ROOMS_STEP_INDEX = 1;
  NOMINATE_ONLINE_ROOMS_STEP_INDEX = 2;
  year = environment.currentAwardYear;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  private userSubscription: Subscription;
  private regularNomListSubscription: Subscription;
  private onlineNomListSubscription: Subscription;
  private companyNomListSubscription: Subscription;
  hideRules = false;
  confirmedRules = false;
  regularNomsReady = false;
  onlineNomsReady = false;
  companyNomsReady = false;
  showingAddNew = false;
  hasInvalidRegularNoms = false;
  hasInvalidOnlineNoms = false;
  hasAutogeneratedRepeatNoms = false;
  hasAutomaticFinalistNoms = false;
  hasAutogeneratedGapNoms = false;
  hasAutogeneratedSuggestedNoms = false;
  regularAutogenComplete = false;
  onlineAutogenComplete = false;
  Status = ApplicationStatus;
  Category = TerpecaCategory;
  roomCountErrorMatcher = new RoomCountErrorStateMatcher();
  @ViewChild('stepper') stepper: MatStepper;

  constructor(private auth: AuthService, public settings: SettingsService, private db: AngularFirestore, private dialog: MatDialog) {
    this.firstFormGroup = new UntypedFormGroup({
      roomCount: new UntypedFormControl('', Validators.required),
      virtualRoomCount: new UntypedFormControl('', Validators.required)
    }, roomCountValidator);
    this.secondFormGroup = new UntypedFormGroup({
      searchText: new UntypedFormControl('')
    });
    this.thirdFormGroup = new UntypedFormGroup({
      searchText: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.userSubscription = this.auth.user.subscribe((user: TerpecaUser) => {
      if (user && (!this.user || (user.uid !== this.user.uid))) {
        this.onlineNomsReady = !this.settings.hasOnlineRoomCategory();
        this.companyNomsReady = !this.settings.hasCompanyCategory();
        if (this.regularNomListSubscription) {
          this.regularNomListSubscription.unsubscribe();
        }
        this.regularNomListSubscription = this.db.collection<TerpecaNomination>('nominations',
          ref => ref.where('year', '==', this.year)
                    .where('userId', '==', user.uid)
                    .where('category', '==', TerpecaCategory.TOP_ROOM)).valueChanges({ idField: 'docId' })
        .subscribe((value: TerpecaNomination[]) => {
          value.sort(compareEntitiesByLocation);
          this.hasInvalidRegularNoms = this.hasInvalidNoms(value);
          this.regularNominationIds = value ? value.map((nom) => nom.docId) : [];
          this.regularNominatedRoomIds = value ? this.getRoomIds(value) : [];
          this.regularNomsReady = true;
        });
        if (this.settings.hasOnlineRoomCategory()) {
          if (this.onlineNomListSubscription) {
            this.onlineNomListSubscription.unsubscribe();
          }
          this.onlineNomListSubscription = this.db.collection<TerpecaNomination>('nominations',
            ref => ref.where('year', '==', this.year)
                      .where('userId', '==', user.uid)
                      .where('category', '==', TerpecaCategory.TOP_ONLINE_ROOM)).valueChanges({ idField: 'docId' })
          .subscribe((value: TerpecaNomination[]) => {
            value.sort(compareEntitiesByLocation);
            this.hasInvalidOnlineNoms = this.hasInvalidNoms(value);
            this.onlineNominationIds = value ? value.map((nom) => nom.docId) : [];
            this.onlineNominatedRoomIds = value ? this.getRoomIds(value) : [];
            this.onlineNomsReady = true;
          });
        }
        if (this.settings.hasCompanyCategory()) {
          if (this.companyNomListSubscription) {
            this.companyNomListSubscription.unsubscribe();
          }
          this.companyNomListSubscription = this.db.collection<TerpecaNomination>('nominations',
            ref => ref.where('year', '==', this.year)
                      .where('userId', '==', user.uid)
                      .where('category', '==', TerpecaCategory.TOP_COMPANY)).valueChanges({ idField: 'docId' })
          .subscribe((value: TerpecaNomination[]) => {
            value.sort(compareEntitiesByLocation);
            this.companyNominationIds = value ? value.map((nom) => nom.docId) : [];
            this.companyNomsReady = true;
          });
        }
      }
      this.user = user;
      if (user) {
        this.firstFormGroup.patchValue(user);
        this.hideRules = this.confirmedRules = user.nominationRulesConfirmed?.includes(this.year) || false;
      }
    });
    this.loadRoomsForCategory(TerpecaCategory.TOP_ROOM);
    if (this.settings.hasOnlineRoomCategory()) {
      this.loadRoomsForCategory(TerpecaCategory.TOP_ONLINE_ROOM);
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    if (this.regularNomListSubscription) {
      this.regularNomListSubscription.unsubscribe();
    }
    if (this.onlineNomListSubscription) {
      this.onlineNomListSubscription.unsubscribe();
    }
    if (this.companyNomListSubscription) {
      this.companyNomListSubscription.unsubscribe();
    }
  }

  readyToStart() {
    return this.regularNomsReady && this.onlineNomsReady && this.companyNomsReady;
  }

  toggleRules() {
    this.hideRules = !this.hideRules;
    window.scroll({
      top: 0,
      left: 0
    });
    if (this.hideRules && !this.user.nominationRulesConfirmed?.includes(this.year)) {
      this.auth.confirmNominationRules();
    }
  }

  lastNominatorYear() {
    return utils.lastNominatorYear(this.user, this.year);
  }

  lastVoterYear() {
    return utils.lastVoterYear(this.user, this.year);
  }

  async stepChanged(event: StepperSelectionEvent, stepper: MatStepper) {
    stepper.selected.interacted = false;
    if (event.selectedIndex === this.NOMINATE_REGULAR_ROOMS_STEP_INDEX) {
      this.showingAddNew = false;
      this.secondFormGroup.reset();
      await this.autogenNominations(TerpecaCategory.TOP_ROOM);
    }
    if (this.settings.hasOnlineRoomCategory() && event.selectedIndex === this.NOMINATE_ONLINE_ROOMS_STEP_INDEX) {
      this.showingAddNew = false;
      this.thirdFormGroup.reset();
      await this.autogenNominations(TerpecaCategory.TOP_ONLINE_ROOM);
    }
  }

  showAddNew() {
    this.showingAddNew = true;
  }

  showSearch() {
    this.showingAddNew = false;
  }

  async autogenNominations(category: TerpecaCategory) {
    const lastNominatorYear = this.lastNominatorYear();
    const lastVoterYear = this.lastVoterYear();
    const nominationIds = (category === TerpecaCategory.TOP_ROOM) ? this.regularNominationIds : this.onlineNominationIds;
    await this.loadRoomsForCategory(category);
    if ((!lastNominatorYear && !lastVoterYear) || nominationIds?.length > 0) {
      if (category === TerpecaCategory.TOP_ROOM) {
        this.regularAutogenComplete = true;
      } else {
        this.onlineAutogenComplete = true;
      }
      return;
    }
    const roomMap = (category === TerpecaCategory.TOP_ROOM) ? this.regularRoomMap : this.onlineRoomMap;
    const autogenRoomIds = new Set<string>();
    if (category === TerpecaCategory.TOP_ROOM) {
      this.regularAutogenRoomIds = autogenRoomIds;
    } else {
      this.onlineAutogenRoomIds = autogenRoomIds;
    }
    const lastNomIdSet = new Set<string>();
    if (lastNominatorYear) {
      const promises: any[] = [];
      await this.db.collection<TerpecaNomination>('nominations').ref
      .where('userId', '==', this.user.uid)
      .where('pending', '==', false)
      .where('category', '==', category).get()
      .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
        snapshot.forEach(async (doc: QueryDocumentSnapshot<TerpecaNomination>) => {
          const nom: TerpecaNomination = doc.data();
          if (nom?.roomId && roomMap.has(nom.roomId) && !autogenRoomIds.has(nom.roomId)) {
            const room: TerpecaRoom = roomMap.get(nom.roomId);
            if (nom.year === lastNominatorYear) {
              if (this.canNominateRoom(room)) {
                autogenRoomIds.add(nom.roomId);
                promises.push(this.nominateExistingRoom(room, nom));
                this.hasAutogeneratedRepeatNoms = true;
              } else if (this.isAutomaticFinalist(room)) {
                this.hasAutomaticFinalistNoms = true;
              }
              lastNomIdSet.add(nom.roomId);
            } else if (nom.year < lastNominatorYear) {
              // This looks for the strange case of nomination eligibility gaps (a.k.a the Storyteller's Secret case). If
              // a room was nominated in a prior year, has been ineligible every year since, but is now again eligible, we
              // add it back here, to make sure it isn't forgotten by the nominator.
              if (this.canNominateRoom(room) && !utils.isIneligible(room) && utils.isIneligible(room, lastNominatorYear)) {
                let hasBeenIneligibleSinceLastNomination = true;
                for (let year = nom.year + 1; year < lastNominatorYear; ++year) {
                  if (!utils.isIneligible(room, year)) {
                    hasBeenIneligibleSinceLastNomination = false;
                    break;
                  }
                }
                if (hasBeenIneligibleSinceLastNomination) {
                  console.log(`adding nomination for room that has been ineligible: ${nom.room}`);
                  autogenRoomIds.add(nom.roomId);
                  promises.push(this.nominateExistingRoom(room, nom));
                  this.hasAutogeneratedGapNoms = true;
                }
              }
            }
          }
        });
      });
      await Promise.all(promises);
      console.log(`added ${autogenRoomIds.size} nominations from previous year's nominations`);
    }
    if (lastVoterYear) {
      const promises: any[] = [];
      const docId = `${this.user.uid}-${lastVoterYear}-${category}`;
      let addCount = 0;
      let lastMaxNomIndex = -1;
      let lastNumNoms = -1;
      await this.db.collection<TerpecaRanking>('rankings').doc(docId).ref.get().then(async (doc) => {
        if (doc.exists) {
          const rankings = <TerpecaRanking>doc.data();
          if (!rankings.submitted) {
            return;
          }
          if (lastNominatorYear) {
            // We look through the whole list first to find the lowest ranking of all their nominees.
            for (let i = 0; i < (rankings.rankedIds?.length || 0); ++i) {
              if (lastNomIdSet.has(rankings.rankedIds[i])) {
                lastMaxNomIndex = i;
              }
            }
            // Now we add in all the rooms that outranked any nominated rooms, since those ones probably
            // should have been nominated as well (or instead). We do skip ineligible rooms here since
            // these aren't rooms they're expecting to see and thus it doesn't make sense to suggest an
            // ineligible room.
            if (lastMaxNomIndex > -1) {
              for (let i = 0; i < lastMaxNomIndex; ++i) {
                const roomId = rankings.rankedIds[i];
                if (!autogenRoomIds.has(roomId) && roomMap.has(roomId)) {
                  const room: TerpecaRoom = roomMap.get(roomId);
                  if (this.canNominateRoom(room) && !utils.isIneligible(room)) {
                    autogenRoomIds.add(roomId);
                    promises.push(this.nominateExistingRoom(room));
                    ++addCount;
                  }
                }
              }
            }
          }
          const maxNoms = (category === TerpecaCategory.TOP_ROOM) ? this.maxRegularNoms() : this.maxOnlineNoms();
          lastNumNoms = (rankings.rankedIds?.length || 0);
          if (autogenRoomIds.size < maxNoms && (lastNumNoms / 2) > lastMaxNomIndex) {
            // We use last year's rankings to suggest even more nominations they may want to include, though we never
            // suggest a room that wasn't in the top half of their rankings, we never suggest more than the maximum
            // number of nominations overall, and we don't suggest rooms we know are ineligible.
            for (let i = 0; i < (lastNumNoms / 2); ++i) {
              const roomId = rankings.rankedIds[i];
              if (!autogenRoomIds.has(roomId) && roomMap.has(roomId)) {
                const room: TerpecaRoom = roomMap.get(roomId);
                if (this.canNominateRoom(room) && !utils.isIneligible(room)) {
                  if (i > lastMaxNomIndex) {
                    lastMaxNomIndex = i;
                  }
                  autogenRoomIds.add(roomId);
                  promises.push(this.nominateExistingRoom(room));
                  ++addCount;
                  this.hasAutogeneratedSuggestedNoms = true;
                  if (autogenRoomIds.size >= maxNoms) {
                    break;
                  }
                }
              }
            }
          }
        }
      });
      await Promise.all(promises);
      console.log(`added ${addCount} nominations from previous year's rankings` +
                  ((lastMaxNomIndex > -1) ? ` (lowest nom is ${lastMaxNomIndex + 1}/${lastNumNoms})` : ``));
    }
    if (category === TerpecaCategory.TOP_ROOM) {
      this.regularAutogenComplete = true;
    } else {
      this.onlineAutogenComplete = true;
    }
  }

  async loadRoomsForCategory(category: TerpecaCategory) {
    await this.db.collection<TerpecaRoom>('rooms').ref.where('category', '==', category).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      const rooms = this.getRoomsFromSnapshot(snapshot).sort(compareEntitiesByLocation);
      if (category === TerpecaCategory.TOP_ROOM) {
        this.regularRoomMap = new Map<string, TerpecaRoom>();
        for (const room of rooms) {
          this.regularRoomMap.set(room.docId, room);
        }
        this.regularRooms = rooms.sort(compareEntitiesByLocation);
      } else if (category === TerpecaCategory.TOP_ONLINE_ROOM) {
        this.onlineRoomMap = new Map<string, TerpecaRoom>();
        for (const room of rooms) {
          this.onlineRoomMap.set(room.docId, room);
        }
        this.onlineRooms = rooms.sort(compareEntitiesByLocation);
      }
    });
  }

  private getRoomsFromSnapshot(snapshot: QuerySnapshot<TerpecaRoom>): TerpecaRoom[] {
    const rooms: TerpecaRoom[] = [];
    snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaRoom>) => {
      const room: TerpecaRoom = doc.data();
      if (room) {
        room.docId = doc.id;
        rooms.push(<TerpecaRoom>room);
      }
    });
    return rooms;
  }

  canNominateRoom(room: TerpecaRoom) {
    return !this.isAffiliatedRoom(room) && !this.isAutomaticFinalist(room) && !this.isNominatedRoom(room);
  }

  roomNominateLabel(room: TerpecaRoom) {
    if (this.isAffiliatedRoom(room)) {
      return 'Affiliated';
    }
    if (this.isAutomaticFinalist(room)) {
      return 'Automatic Finalist';
    }
    if (this.isNominatedRoom(room)) {
      return 'Nominated';
    }
    return 'Nominate';
  }

  isNominatedRoom(room: TerpecaRoom) {
    return this.regularNominatedRoomIds.includes(room.docId) || this.onlineNominatedRoomIds?.includes(room.docId);
  }

  isAffiliatedRoom(room: TerpecaRoom) {
    return this.user.affiliatedRoomIds && this.user.affiliatedRoomIds.includes(room.docId);
  }

  isAutomaticFinalist(room: TerpecaRoom) {
    return utils.isAutomaticFinalist(room);
  }

  maxRegularNoms() {
    return utils.maxRegularNoms(this.user);
  }

  maxOnlineNoms() {
    return utils.maxOnlineNoms(this.user);
  }

  numRegularNoms() {
    return this.regularNominationIds?.length || 0;
  }

  numOnlineNoms() {
    return this.onlineNominationIds?.length || 0;
  }

  numCompanyNoms() {
    return this.companyNominationIds?.length || 0;
  }

  private hasInvalidNoms(noms: TerpecaNomination[]) {
    for (const nom of noms) {
      if (!utils.isValidNomination(nom)) {
        return true;
      }
    }
    return false;
  }

  private getRoomIds(noms: TerpecaNomination[]) {
    const roomIds: string[] = [];
    for (const nom of noms) {
      if (nom.roomId) {
        roomIds.push(nom.roomId);
      }
    }
    return roomIds;
  }

  async saveApplicationData() {
    if (!this.firstFormGroup.valid) {
      return;
    }
    await this.auth.updateUser(this.firstFormGroup.value);
    this.firstFormGroup.markAsPristine();
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  hasTooManyRegularNoms() {
    return this.numRegularNoms() > this.maxRegularNoms();
  }

  hasTooManyOnlineNoms() {
    return this.numOnlineNoms() > this.maxOnlineNoms();
  }

  allNominationsAreValid() {
    return !this.hasInvalidRegularNoms && !this.hasInvalidOnlineNoms && !this.hasTooManyRegularNoms() && !this.hasTooManyOnlineNoms();
  }

  async nominateExistingRoom(room: TerpecaRoom, prevNomination?: TerpecaNomination) {
    if (!this.canNominateRoom(room)) {
      return;
    }
    console.log(`nominating existing room: ${room.name}`);
    const newNomination: TerpecaNomination = {
      // RoomDescription fields carried over from TerpecaRoom
      category: room.category,
      company: room.company,
      city: room.city,
      state: room.state || '',
      country: room.country,
      link: room.link,
      email: room.email || '',
      languages: room.languages?.length ? room.languages : null,  // If unset, nominator must set
      horrorLevel: room.horrorLevel || null,  // If unset, nominator must set

      // TerpecaNomination fields
      room: room.name + (room.englishName ? (' [' + room.englishName + ']') : ''),
      createTime: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
      userId: this.auth.currentUser.uid,
      year: this.year,

      // Existing rooms default to "No, please check" for confirmations.
      // (New room nominations leave these unset, requiring explicit input.)
      confirmedDates: false,
      confirmedEnglish: false,
      roomId: room.docId,
      userMatched: true,
      pending: true,
    };

    if (room.englishLink) {
      newNomination.englishLink = room.englishLink;
    }
    if (prevNomination) {
      if ([true, false].includes(prevNomination.confirmedEnglish)) {
        newNomination.confirmedEnglish = prevNomination.confirmedEnglish;
      }
      if ([true, false].includes(prevNomination.confirmedNoConflicts)) {
        newNomination.confirmedNoConflicts = prevNomination.confirmedNoConflicts;
      }
      if (prevNomination.versionPlayed) {
        newNomination.versionPlayed = prevNomination.versionPlayed;
      }
    }
    await this.db.collection<TerpecaNomination>('nominations').doc(this.db.createId()).set(newNomination);
  }

  async reopenNominations() {
    if (!this.settings.isNominationOpen(this.user) || this.settings.isPastNominationDeadline()) {
      return;
    }
    if (this.auth.currentUser.nominationsSubmitted &&
        this.auth.currentUser.nominationsSubmitted.includes(this.year)) {
      await this.auth.reopenNominations();
    }
  }

  async confirmSubmitNominations() {
    this.dialog.open(SimpleDialogComponent, {
      data: {
        title: 'Confirm Your Nominations',
        message: 'IMPORTANT: By submitting these nominations, you are publicly acknowledging that:' +
        '<ul><li>you have personally played all the rooms you have nominated</li>' +
        '<li>you do not have any conflict of interest related to the games you have nominated</li></ul>' +
        'Note that if it is found later that you are not acting in good faith and have chosen to nominate games for any other reason than ' +
        'helping the project, you will be disqualified from further participation in the project and your actions may damage the reputation ' +
        'of yourself and any company you may be associated with.',
        okCallback: () => { this.submitNominations(); },
        okLabel: 'Confirm and Submit'
      }
    });
  }

  async submitNominations() {
    if (!this.allNominationsAreValid() || !this.settings.isNominationOpen(this.user) || this.settings.isPastNominationDeadline()) {
      return;
    }
    if (!this.auth.currentUser.nominationsSubmitted ||
        !this.auth.currentUser.nominationsSubmitted.includes(this.year)) {
      await this.auth.submitNominations();
    }
  }
}
