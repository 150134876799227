import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import app from 'firebase/compat/app';
import { Subscription } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { HorrorLevel, NominationId, TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { MatchComponent } from 'src/app/views/match/match.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nomination',
  templateUrl: './nomination.component.html',
  styleUrl: './nomination.component.css'
})
export class NominationComponent implements OnInit, OnDestroy {
  ready = false;
  year = environment.currentAwardYear;
  @Input() nominationId: string;
  @Input() nomination: TerpecaNomination;
  @Input() matcher: MatchComponent;
  @Input() allowEdit: boolean = false;
  @Input() category: TerpecaCategory = TerpecaCategory.TOP_ROOM;
  editable = false;
  @Input() allowSelect: boolean = false;
  @Input() selected: boolean = false;
  @Input() showDetails: boolean = false;
  @Input() showEligibility: boolean = false;
  @Input() showUserData: boolean = false;
  @Input() room: TerpecaRoom;
  userData: TerpecaUser;
  formGroup: UntypedFormGroup;
  @ViewChild('firstInput') firstInput: ElementRef;
  getLocationString = utils.getLocationString;

  private nominationSubscription: Subscription;
  private roomSubscription: Subscription;

  constructor(private auth: AuthService, public settings: SettingsService, private db: AngularFirestore) { }

  ngOnInit() {
    if (this.allowEdit) {
      this.formGroup = new UntypedFormGroup({
        userId: new UntypedFormControl(this.auth.currentUser.uid),
        year: new UntypedFormControl(this.year),
        category: new UntypedFormControl(this.category),
        roomId: new UntypedFormControl(null),

        room: new UntypedFormControl('', Validators.required),
        company: new UntypedFormControl('', Validators.required),
        city: new UntypedFormControl('', !this.isOnlineRoom() ? Validators.required : null),
        state: new UntypedFormControl('', !this.isOnlineRoom() ? Validators.required : null),
        country: new UntypedFormControl('', Validators.required),
        languages: new UntypedFormControl('', Validators.required),
        horrorLevel: new UntypedFormControl('', Validators.required),
        link: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.email),

        confirmedDates: new UntypedFormControl(null, Validators.required),
        confirmedEnglish: new UntypedFormControl(null, Validators.required),
        confirmedNoConflicts: new UntypedFormControl(null, Validators.requiredTrue),

        englishLink: new UntypedFormControl(''),
        versionPlayed: new UntypedFormControl(''),
        dataProblem: new UntypedFormControl('')
      });
      this.editable = !this.nominationId && !this.nomination;
    }
    if (this.nominationId) {
      this.nominationSubscription = this.db.collection<TerpecaNomination>('nominations').doc(this.nominationId).valueChanges()
      .subscribe((value: TerpecaNomination) => {
        this.nomination = value;
        this.room = null;
        if (value && this.formGroup) {
          this.formGroup.patchValue(value, { emitEvent: false });
        }
        if (this.nomination.roomId) {
          if (this.roomSubscription) {
            this.roomSubscription.unsubscribe();
          }
          this.roomSubscription = this.db.collection<TerpecaRoom>('rooms').doc(this.nomination.roomId).valueChanges()
          .subscribe((room: TerpecaRoom) => {
            this.room = room;
            this.updateReadiness();
          });
        }
        this.updateReadiness();
      });
    }
    if (this.nomination && this.showUserData && this.auth && this.auth.currentUser && this.auth.currentUser.isOwner) {
      this.db.collection<TerpecaUser>('users').doc(this.nomination.userId).ref.get().then(snapshot => {
        this.userData = snapshot.exists ? <TerpecaUser>snapshot.data() : null;
      });
    }
    this.updateReadiness();
  }

  updateReadiness() {
    if (this.nominationId && !this.nomination) {
      return;
    }
    if (!this.matcher && this.nomination?.roomId && !this.room) {
      return;
    }
    this.ready = true;
  }

  ngOnDestroy() {
    if (this.nominationSubscription) {
      this.nominationSubscription.unsubscribe();
    }
    if (this.roomSubscription) {
      this.roomSubscription.unsubscribe();
    }
  }

  backgroundColor() {
    if (this.selected) {
      return 'lightgrey';
    }
    return 'inherit';
  }

  isOnlineRoom() {
    if (this.nomination) {
      return this.nomination.category === TerpecaCategory.TOP_ONLINE_ROOM;
    }
    return this.category === TerpecaCategory.TOP_ONLINE_ROOM;
  }

  isInPersonRoom() {
    if (this.nomination) {
      return this.nomination.category === TerpecaCategory.TOP_ROOM;
    }
    return this.category === TerpecaCategory.TOP_ROOM;
  }

  isCompany() {
    if (this.nomination) {
      return this.nomination.category === TerpecaCategory.TOP_COMPANY;
    }
    return this.category === TerpecaCategory.TOP_COMPANY;
  }

  isValidNomination() {
    return utils.isValidNomination(this.editable ? this.formGroup.value : this.nomination);
  }

  isApproved() {
    return this.room && utils.isApproved(this.room, this.nomination?.year);
  }

  isIneligible() {
    return this.room && utils.isIneligible(this.room, this.nomination?.year);
  }

  isPermanentlyIneligible() {
    return this.room && utils.isPermanentlyIneligible(this.room, this.nomination?.year);
  }

  isNominee() {
    return this.room && utils.isNominee(this.room, this.nomination?.year);
  }

  isFinalist() {
    return this.room && utils.isFinalist(this.room, this.nomination?.year);
  }

  isWinner() {
    return this.room && utils.isWinner(this.room, this.nomination?.year);
  }

  // Returns true if we can't assume this is an English-only room.
  requiresEnglishCheck() {
    // TODO: Unify assumption logic between this and LanguagePickerComponent?
    return !(
        this.isApproved() ||
        (this.room && utils.isEnglishSpeaking(this.room.country, this.room.state)) ||
        (this.formGroup?.value && utils.isEnglishSpeaking(this.formGroup.value.country, this.formGroup.value.state))
    );
  }

  primaryName() {
    if (this.isCompany()) {
      return this.room?.company || this.nomination?.company || '';
    }
    return this.room?.englishName || this.room?.name || this.nomination?.room || '';
  }

  secondaryName() {
    return this.room?.englishName ? this.room.name : '';
  }

  languages() {
    const roomLanguages = utils.getLanguages(this.room);
    return roomLanguages?.length ? roomLanguages : utils.getLanguages(this.nomination);
  }

  horrorLevel() {
    return this.room?.horrorLevel || this.nomination?.horrorLevel || HorrorLevel.UNKNOWN;
  }

  company() {
    return this.room?.company || this.nomination?.company || '';
  }

  location() {
    return utils.getLocationString(this.room || this.nomination);
  }

  link() {
    return this.room?.link || this.nomination?.link || '';
  }

  englishLink() {
    return this.room?.englishLink || this.nomination?.englishLink || '';
  }

  email() {
    return this.room?.email || this.nomination?.email || '';
  }

  getNominationId() {
    return this.nominationId || this.nomination.docId;
  }

  toggleSelected() {
    if (this.matcher) {
      this.matcher.toggleSelectedNomination(this.getNominationId());
    }
  }

  toggleEditable() {
    const x = window.scrollX;
    const y = window.scrollY;
    this.editable = !this.editable;
    if (this.editable) {
      this.formGroup.markAllAsTouched();
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    }
    setTimeout(() => {
      window.onscroll = () => { };
    });
  }

  async save() {
    if (!utils.isValidNomination(this.formGroup.value)) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (!this.requiresEnglishCheck()) {
      // Scrub leftover values input for a different country
      this.formGroup.patchValue({ languages: null, confirmedEnglish: null });
    }
    if (!this.nominationId) {
      const newNomination = <TerpecaNomination>this.formGroup.value;
      newNomination.createTime = <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp();
      newNomination.pending = true;
      if (!utils.isValidNomination(newNomination)) {
        return;
      }
      utils.trimInPlace(newNomination);
      await this.db.collection<TerpecaNomination>('nominations').doc(this.db.createId()).set(newNomination);
      this.formGroup.reset({
        userId: this.auth.currentUser.uid,
        year: this.year,
        category: this.category,
        country: this.formGroup.value.country
      });
      this.formGroup.clearValidators();
      this.firstInput.nativeElement.focus();
    } else {
      utils.trimInPlace(this.formGroup.value);
      await this.db.collection<TerpecaNomination>('nominations').doc(this.nominationId).update(this.formGroup.value);
      this.formGroup.markAsPristine();
      this.editable = false;
    }
  }

  async delete() {
    if (this.nomination && this.nominationId) {
      const batch = this.db.firestore.batch();
      batch.delete(this.db.firestore.collection('nominations').doc(this.nominationId));
      if (this.nomination.roomId) {
        const nomId: NominationId = { nominationId: this.nominationId, year: this.year };
        batch.update(this.db.firestore.collection('rooms').doc(this.nomination.roomId), {
          nominations: <NominationId[]><unknown>app.firestore.FieldValue.arrayRemove(nomId)
        });
      }
      await batch.commit();
    }
  }

  async unmatch() {
    if (this.matcher && this.matcher.canProcessRawNominations() && this.nomination) {
      await this.matcher.unmatchNomination(this.nomination);
    }
  }
}
